.p-inline-message.p-inline-message-warn {
  background: var(--app-role-community-leader-background-color);
  border: solid var(--app-input-focus-icon-and-buttons-color);
  border-width: 0px;
  color: var(--app-input-focus-icon-and-buttons-color);
  width: 340px;
  margin-bottom: 30px;
  margin-top: 30px;
}

#sidebar-reset-password-warning {
  .p-inline-message.p-inline-message-warn {
    width: 228px !important;
  }

  .p-inline-message .p-inline-message-text {
    font-size: 13px;
  }
}
