.p-component.p-confirm-popup {
  width: 280px;
  .p-confirm-popup-footer {
    text-align: center;
    padding-top: 0;
    .p-confirm-popup-reject {
      background-color: var(--app-background-color);
      color: var(--app-input-focus-icon-and-buttons-color);
      letter-spacing: -0.015em;
      width: 66px;
      height: 35px;
      border: 1px solid var(--app-p-steps-border-color);
      padding: 0;
    }

    .p-confirm-popup-accept {
      width: 100px;
      height: 35px;
      padding: 8px 16px 8px 16px;
      border-radius: 8px;
      letter-spacing: -0.015em;
    }
  }
}

.p-confirm-popup:after,
.p-confirm-popup:before {
  content: none;
}
