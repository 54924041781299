:root{
    --app-background-color: #fffcf6;
    --app-titles-color: #1D1916;
    --app-paragraph-color: #615647;
    --app-login-selectButton-focus-background-color: #F5F4F1;
    --app-login-selectButton-unfocused-color:#776D57;
    --app-input-focus-color: #9A9375;
    --app-input-border-color: #E5E5DC;
    --app-input-placeholder-color: #B1AC93;
    --app-input-focus-icon-and-buttons-color:#A86B30;
    --app-success-message-color:#03A330;
    --app-error-message-color:#F04438;
    --app-message-background-color:#FFFCF6;
    --app-p-steps-border-color:#cecbba;
    --app-button-hover-cover:#83471d;
    --app-button-transparent-hover-color: #ece9e2;
    --app-p-inputtext-enabled-focus:#f4e5d7;
    --app-sidebar-background-color:#FFF8EB;
    --app-sidebar-right-border-color:#CECBBA;
    --app-sidebar-user-phone-number: #8C8366;
    --app-role-admin-background-color: #FAF4FB;
    --app-role-admin-color:#762C82;
    --app-role-community-leader-background-color: #F8F0E7;
    --app-incident-status-open-text-color:#2c8258;
    --app-incident-status-open-background-color:#ecf9f2;
    --app-incident-status-closed-text-color:#b31142;
    --app-incident-status-closed-background-color:#fef1f5;
}