p-inputmask {
  width: 100%;
  border-bottom-left-radius: 0;

  .p-inputtext {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .p-element.ng-pristine.ng-invalid.ng-dirty.ng-touched {
    input.p-inputtext.p-component.p-element.p-inputmask {
      border-color: var(--app-error-message-color);
      box-shadow: none;
    }
  }
}

p-inputmask.p-element.ng-pristine.ng-invalid.ng-dirty.ng-touched {
  input.p-inputtext.p-component.p-element.p-inputmask {
    border-color: var(--app-error-message-color);
    box-shadow: none;
  }
}

p-inputmask.p-element.ng-pristine.ng-invalid.ng-touched {
  input.p-inputtext.p-component.p-element.p-inputmask {
    border-color: var(--app-error-message-color);
    box-shadow: none;
  }
}

p-inputmask.p-element.ng-dirty.ng-invalid.ng-touched {
  input.p-inputtext.p-component.p-element.p-inputmask {
    border-color: var(--app-error-message-color);
    box-shadow: none;
  }
}

p-inputmask.p-element.ng-touched.p-inputwrapper-filled {
  input.p-inputtext.p-component.p-element.p-inputmask {
    border-color: var(--app-error-message-color);
    box-shadow: none;
  }
}

p-inputmask.p-element.ng-touched.p-inputwrapper-filled.ng-dirty.ng-valid.p-inputwrapper-focus {
  input.p-inputtext.p-component.p-element.p-inputmask {
    box-shadow: 0px 0px 0px 4px #f4e5d7;
    border: 1px solid var(--app-input-focus-color);
  }
}

p-inputmask.p-element.ng-touched.p-inputwrapper-filled.ng-dirty.ng-valid {
  input.p-inputtext.p-component.p-element.p-inputmask {
    border-color: var(--app-input-border-color);
  }
}

p-inputmask.p-element.ng-touched.p-inputwrapper-filled.ng-valid {
  input.p-inputtext.p-component.p-element.p-inputmask {
    border-color: var(--app-input-border-color);
  }
}

p-inputmask.p-element.ng-touched.p-inputwrapper-filled.ng-valid.p-inputwrapper-focus {
  input.p-inputtext.p-component.p-element.p-inputmask {
    box-shadow: 0px 0px 0px 4px #f4e5d7;
    border: 1px solid var(--app-input-focus-color);
  }
}
