.p-datatable-table {
  border-top: 1px solid var(--app-sidebar-right-border-color);
  border-left: 1px solid var(--app-sidebar-right-border-color);
  border-right: 1px solid var(--app-sidebar-right-border-color);
  border-radius: 8px;
}

.p-datatable .p-datatable-thead > tr > th {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-weight: 600;
}

.p-datatable .p-datatable-tbody > tr {
  font-weight: 400;

  > td {
    padding: 12px 16px 12px 16px;
  }
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr {
  color: var(--app-titles-color);
  font-size: 14px;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  border-bottom: 1px solid var(--app-sidebar-right-border-color);
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr {
  background: var(--app-background-color);
}

.p-datatable .p-datatable-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 8px;
}

.p-datatable .p-datatable-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 8px;
}

#dashboard-reporters-table {
  .p-datatable {
    height: fit-content;
  }
  .p-datatable > .p-datatable-wrapper {
    height: 100%;
  }
}

#dashboard-reporters-table .full-height {
  .p-datatable {
    height: 100%;
  }
  .p-datatable-table {
    height: 100%;
  }
}
