div.p-dropdown.p-component.p-inputwrapper {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 43px;
  width: 110px;

  span {
    display: flex;
    width: 100%;
  }

  div.p-dropdown-trigger {
    width: 35px;
  }

  .p-dropdown-panel.p-component.ng-star-inserted {
    width: 336px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: var(--app-paragraph-color);
    background: var(--app-p-inputtext-enabled-focus);
  }
}

.p-element.p-dropdown-label.p-inputtext.ng-star-inserted span.ng-star-inserted {
  display: none;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0px 0px 0px 4px var(--app-p-inputtext-enabled-focus);
  border: 1px solid var(--app-input-focus-color);
  z-index: 100;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: var(--app-input-border-color);
}
.ng-dirty.ng-invalid > div#location.p-dropdown.p-component.p-inputwrapper {
  border-color: var(--app-error-message-color);
  box-shadow: none;

  .p-dropdown-trigger-icon {
    color: var(--app-error-message-color);
  }
}

div#phone-number-countries-dropdown.p-dropdown.p-component.p-inputwrapper {
  .p-dropdown-panel.p-component.ng-star-inserted {
    width: 360px;
    height: 266px;
  }

  .p-scroller-content {
    padding: 0;
  }

  .p-scroller {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
}

div#location.p-dropdown.p-component.p-inputwrapper,
div#role.p-dropdown.p-component.p-inputwrapper {
  width: 336px;
  border-radius: 8px;
  height: 44px;
  border: 1px solid var(--app-input-border-color);

  div.p-dropdown-trigger {
    width: 3rem;
  }

  .p-icon {
    color: var(--app-input-focus-icon-and-buttons-color);
  }

  .p-placeholder {
    color: var(--app-input-placeholder-color);
    align-self: center;
    font-size: 14px;
  }
}

div#add-user-location.p-dropdown.p-component.p-inputwrapper {
  width: 100%;
  border-radius: 6px;
  height: 44px;
  border: 1px solid var(--app-input-border-color);

  div.p-dropdown-trigger {
    width: 3rem;
  }

  .p-icon {
    color: var(--app-input-focus-icon-and-buttons-color);
  }

  .p-placeholder {
    color: var(--app-input-placeholder-color);
    align-self: center;
  }

  .p-dropdown-clear-icon {
    top: 50%;
  }
}

.field-editable {
  div#location.p-dropdown.p-component.p-inputwrapper,
  div#role.p-dropdown.p-component.p-inputwrapper {
    height: 40px;
    width: 100%;
    max-width: 360px;
    background: var(--app-background-color);

    .p-dropdown-filter.p-inputtext.p-component {
      height: 24px;
    }

    div.p-dropdown-trigger {
      width: 3rem;
    }

    .p-icon {
      color: var(--app-input-focus-icon-and-buttons-color);
    }

    .p-placeholder {
      color: var(--app-input-placeholder-color);
      align-self: center;
      font-size: 14px;
    }
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    height: 30px;
  }

  div.p-dropdown.p-component.p-inputwrapper {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 43px;
    width: 116px;

    span {
      display: flex;
      width: 100%;
    }

    div.p-dropdown-trigger {
      width: 35px;
    }

    .p-dropdown-panel.p-component.ng-star-inserted {
      width: 116px;
      background: var(--app-background-color);
      .p-dropdown-header.ng-star-inserted {
        background: var(--app-background-color);
      }
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      color: var(--app-input-focus-icon-and-buttons-color);
      background: var(--app-p-inputtext-enabled-focus);
    }
    .p-inputtext {
      width: 100%;
      background: var(--app-background-color);
    }
  }
}

div#chart.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled,
div#time-options-dropdown.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 28px;
  padding: 10px;
  gap: 4px;
  border-radius: 8px;
  background: #fffcf6;
  border: 1px solid #cecbba;
  box-shadow: 0px 1px 1px -0.5px #00000033;

  span.p-element.p-dropdown-label.p-inputtext.ng-star-inserted {
    width: 100%;
    height: 20px;
    padding: 0px 4px 0px 4px;
    gap: 10px;
  }

  div.p-dropdown-trigger {
    color: var(--app-input-focus-icon-and-buttons-color);
    margin: 0 -15px 0 0;
  }

  .p-inputtext {
    width: 100%;
    color: var(--app-input-focus-icon-and-buttons-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .p-dropdown-panel.p-component.ng-star-inserted {
    width: 100%;
    background: var(--app-background-color);

    .p-dropdown-header.ng-star-inserted {
      background: var(--app-background-color);
    }
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    height: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;

    span {
      width: 100%;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  }
}

div#time-options-dropdown.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
  height: 36px;

  .p-inputtext {
    font-weight: 600;
  }

  .p-dropdown-panel.p-component.ng-star-inserted {
    width: 180px;
  }

  .p-overlay.p-component {
    left: -71px !important;
  }
}
