.p-scroller {
  background: var(--app-message-background-color);
  border: 0.5px solid var(--app-sidebar-right-border-color);
  border-radius: 8px;
  box-shadow: 0 1px 1px -0.5px #49413a1a, 0 3px 3px -1.5px #49413a1a;
  scrollbar-width: thin;
}

.p-scroller-content {
  padding: 0 0.75rem 0 0.75rem;
}
