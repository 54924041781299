.p-multiselect {
  border-radius: 8px;
  border: 1px solid var(--app-input-border-color);

  .p-placeholder {
    color: var(--app-input-placeholder-color);
    align-self: center;
    font-size: 14px;
  }
  .p-icon {
    color: var(--app-input-focus-icon-and-buttons-color);
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--app-paragraph-color);
    background: var(--app-paragraph-color);
    .p-icon {
      color: var(--app-input-border-color);
    }
  }

  .p-checkbox .p-checkbox-box:hover {
    border-color: var(--app-paragraph-color);
  }
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: 0px 0px 0px 4px var(--app-p-inputtext-enabled-focus);
  border: 1px solid var(--app-input-focus-color);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--app-paragraph-color);
  background: var(--app-p-inputtext-enabled-focus);
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: var(--app-error-message-color) !important;
}

.ng-dirty.ng-invalid > .p-multiselect .p-icon {
  color: var(--app-error-message-color) !important;
}

.field-editable {
  div#occupation-multiselect.p-multiselect.p-component.p-inputwrapper.p-multiselect-chip,
  div#livestock-multiselect.p-multiselect.p-component.p-inputwrapper.p-multiselect-chip {
    height: 40px;
    width: 100%;
    max-width: 360px;
    background: var(--app-background-color);
  }
  .p-multiselect-panel
    .p-multiselect-items
    .p-multiselect-item:not(.p-highlight):not(.p-disabled) {
    background: var(--app-background-color);
  }
  .p-multiselect-panel .p-multiselect-items {
    padding: 0;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 0.75rem 1.25rem 0.75rem 0.75rem;
  }

  .p-multiselect-panel {
    width: 100%;
  }
}
