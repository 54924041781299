@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "scss/colors-variables.css";
@import "scss/variables.scss";
@import "scss/primeng-components/submit-button.scss";
@import "scss/primeng-components/stepper.scss";
@import "scss/primeng-components/select-button.scss";
@import "scss/primeng-components/input-filed.scss";
@import "scss/primeng-components/input-maskfield.scss";
@import "scss/primeng-components/input-dropdown.scss";
@import "scss/primeng-components/button.scss";
@import "scss/primeng-components/icon.scss";
@import "scss/primeng-components/multiselect.scss";
@import "scss/primeng-components/toaster.scss";
@import "scss/primeng-components/menu-popup.scss";
@import "scss/primeng-components/table.scss";
@import "scss/primeng-components/paginator.scss";
@import "scss/primeng-components/avatar.scss";
@import "scss/primeng-components/drawer.scss";
@import "scss/primeng-components/tables-chip.scss";
@import "scss/primeng-components/confirmpopup.scss";
@import "scss/primeng-components/autocomplete.scss";
@import "scss/primeng-components/calendar.scss";
@import "scss/primeng-components/image.scss";
@import "./scss/primeng-components/virtual-scroller.scss";
@import "scss/primeng-components/chart.scss";
@import "scss/primeng-components/message.scss";

@font-face {
  font-family: inter;
  src: url(assets/fonts/Inter-VariableFont.ttf);
}

body {
  font-size: 14px;
  margin: 0;
  background-color: var(--app-background-color);
  min-height: 100vh;
  height: 100%;
}

h1 {
  color: var(--app-titles-color);
  font-weight: 600;
  font-size: 30px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
p,
caption,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

q {
  quotes: none;
}
