.p-autocomplete {
  height: 36px;
  width: 380px;

  .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 2px 6px 2px 6px;
    border-radius: 4px;
    color: var(--app-paragraph-color);
  }

  .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: 0;
    font-size: 14px;
    height: 100%;
    align-items: center;
    color: var(--app-input-placeholder-color) !important;
  }

  ul {
    border-radius: 8px;
    background: var(--app-background-color);
    border: 1px solid var(--app-sidebar-right-border-color);
  }

  input::placeholder {
    color: var(--app-input-placeholder-color);
    font-weight: 400;
  }
}

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0;

  .p-autocomplete-item {
    height: 30px;
    margin-bottom: 4px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .p-autocomplete-item.p-highlight {
    color: var(--app-input-focus-icon-and-buttons-color);
    background: var(--app-sidebar-background-color);
  }
}

.p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
  box-shadow: 0 0 0 0.2rem var(--app-p-inputtext-enabled-focus) !important;
}
