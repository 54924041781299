.p-button {
  width: 100%;
  background-color: var(--app-input-focus-icon-and-buttons-color);
  border: none;
}

.p-button:focus {
  box-shadow: none;
}

button[pButton]:hover {
  background-color: var(--app-button-hover-cover);
}
