.p-menu.p-menu-overlay {
  width: 228px;
  border-radius: 8px;
  padding: 8px;
  margin-top: -4px;
  background-color: var(--app-message-background-color);
  border: 0.5px solid var(--app-sidebar-right-border-color);
  box-shadow: 0px 1px 1px -0.5px #49413a1a, 0px 3px 3px -1.5px #49413a1a;
}

.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
  .p-menuitem-text {
    font-size: 14px;
    font-weight: 500;
    color: var(--app-paragraph-color);
  }

  .p-menuitem-icon {
    width: 12px;
    height: 12px;
    color: var(--app-input-focus-color);
    margin-right: 1rem;
    margin-top: 0.7rem;
  }
}

.p-menu .p-menuitem-link:focus,
.p-menu
  .p-menuitem:not(.p-highlight):not(.p-disabled)
  > .p-menuitem-content:hover {
  border-radius: 6px;
  background-color: #e9ecef;
}

.p-menuitem {
  &:not(.p-highlight):not(.p-disabled) {
    &.p-focus {
      .p-menuitem-content {
        background-color: var(--app-message-background-color);
      }
    }
  }
}

.p-menu .p-submenu-header {
  background-color: var(--app-message-background-color);
  font-weight: 500;
  font-size: 12px;
  color: var(--app-sidebar-user-phone-number);
}

.p-menu .p-menuitem > .p-menuitem-content,
.p-menu .p-menuitem > .p-menuitem-content .p-menuitem-link {
  height: 30px;
  margin-top: 4px;
  margin-bottom: 4px;
}

#sidebar-user-menu {
  .p-menu-overlay {
    top: -89px;
  }
}
