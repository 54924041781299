.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: var(--app-input-focus-icon-and-buttons-color);
  color: white;
  border: none;
}

.p-steps .p-steps-item .p-steps-number {
  background: transparent;
  font-weight: 500;
  border: 2px solid var(--app-p-steps-border-color);
  margin-right: 65px;
}

.p-steps .p-steps-item:last-child::before {
  visibility: hidden;
}

.p-steps .p-steps-item:before {
  border-top: 3px solid var(--app-p-steps-border-color);
  width: 44px;
  top: 80%;
  margin-left: 39px;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  min-width: 28px;
  height: 28px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}

.completed {
  .p-steps-number {
    color: transparent;
  }
  span.p-steps-number {
    border: 2px solid #a86b30 !important;
  }
}

li.p-element.p-steps-item.completed.p-disabled::before {
  border-top: 3px solid var(--app-input-focus-icon-and-buttons-color);
}

.p-steps-item .p-menuitem-link {
  background-color: var(--app-background-color);
}
