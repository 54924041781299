.p-paginator {
  background-color: var(--app-message-background-color);
  justify-content: flex-start;
  padding: 0;

  .p-paginator-current {
    font-size: 12px;
    font-weight: 400;
    color: var(--app-paragraph-color);
    cursor: default !important;
  }
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-last {
  display: none;
}

.p-ripple.p-element.p-paginator-prev.p-paginator-element {
  margin-left: auto;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  height: 35px;
  border-radius: 6px;
  background-color: var(--app-input-focus-icon-and-buttons-color);
  border: 0.5px solid var(--app-input-focus-icon-and-buttons-color);
  color: var(--app-message-background-color);
  font-weight: 400;
}

.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  margin: 0.05rem;
  color: var(--app-login-selectButton-unfocused-color);
  min-width: 2rem;
  height: 35px;
  border-radius: 6px;
}
