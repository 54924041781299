.p-button {
  width: 100%;
  background-color: var(--app-input-focus-icon-and-buttons-color);
  border: none;
}

.p-button:focus {
  box-shadow: none;
}

.p-ripple.p-element.p-button.p-component.p-disabled.p-button-loading.p-button-loading-label-only {
  width: 100%;
  display: flex;
  justify-content: center;

  .p-button-label {
    max-width: fit-content;
  }
}
