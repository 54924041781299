.p-inputtext {
  font-size: 14px;
  width: 100%;
  border-color: var(--app-input-border-color);
  color: var(--app-titles-color);
}

.p-inputtext:enabled:focus.p-inputtext.ng-dirty.ng-invalid.p-filled {
  border-color: var(--app-error-message-color);
  box-shadow: none;
}

.p-inputtext:enabled:focus.p-inputtext.ng-dirty.ng-invalid.ng-valid.p-filled {
  border: 1px solid var(--app-input-focus-color);
}

.p-inputtext:enabled.p-inputtext.ng-dirty.ng-invalid.p-filled {
  border-color: var(--app-error-message-color);
  box-shadow: none;
}

.p-inputtext:enabled.p-inputtext.ng-invalid.ng-dirty.ng-touched {
  border-color: var(--app-error-message-color);
  box-shadow: none;
}

.p-inputtext:enabled.p-inputtext.ng-invalid.ng-dirty.ng-pristine.ng-touched {
  border-color: var(--app-error-message-color);
  box-shadow: none;
}

.p-inputtext:enabled.p-inputtext.ng-invalid.ng-pristine.ng-touched {
  border-color: var(--app-error-message-color);
  box-shadow: none;
}

.p-inputtext:enabled.p-inputtext.ng-dirty.ng-invalid.ng-valid.p-filled {
  border: 1px solid var(--app-input-focus-color);
}

.p-inputtext::placeholder {
  color: var(--app-input-placeholder-color);
}

.p-inputtext:enabled:focus {
  box-shadow: 0px 0px 0px 4px var(--app-p-inputtext-enabled-focus);
  border: 1px solid var(--app-input-focus-color);
}

.p-password-meter,
.p-password-panel {
  display: none;
}

.p-inputwrapper-focus .p-input-icon-right > .p-icon-wrapper {
  color: var(--app-input-focus-icon-and-buttons-color);
}

.p-password {
  width: 100%;
}

.p-error {
  color: var(--app-error-message-color);
  font-size: 14px;
}

.mismatch {
  border-color: var(--app-error-message-color) !important;
  box-shadow: none !important;
}

.field-editable {
  span {
    width: 100%;
    max-width: 360px;

    .p-inputtext {
      width: 100%;
      background: var(--app-background-color);
    }
  }
}

#change-password-container,
#reset-password-form-container {
  .p-input-icon-right {
    width: 100%;
  }
}
