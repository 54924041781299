.p-chip {
  background-color: var(--app-input-focus-icon-and-buttons-color);
  color: var(--app-message-background-color);
  position: relative;
  height: 1.75rem;
  top: -1rem;
  border-radius: 0.5rem;
  margin-bottom: -1.5rem;
  cursor: default;

  .p-chip-text {
    font-size: 14px;
  }
}
