.p-toast .p-toast-message.p-toast-message-success {
  background-color: var(--app-success-message-color);
  box-shadow: 0px 1px 1px -0.5px #49413a1a, 0px 3px 3px -1.5px #49413a1a,
    0px 6px 6px -3px #49413a1a, 0px 12px 12px -6px #cecbba1a,
    0px 24px 24px -12px #cecbba1a;
  color: var(--app-message-background-color);
  border-radius: 6px;
  border: none;
  text-align: center;
  width: 350px;
}

.p-toast .p-toast-message.p-toast-message-error {
  background-color: var(--app-error-message-color);
  box-shadow: 0px 1px 1px -0.5px #49413a1a, 0px 3px 3px -1.5px #49413a1a,
    0px 6px 6px -3px #49413a1a, 0px 12px 12px -6px #cecbba1a,
    0px 24px 24px -12px #cecbba1a;
  color: var(--app-message-background-color);
  border-radius: 6px;
  border: none;
  text-align: center;
  width: 350px;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 0;
  margin: 0.35rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  margin-top: 0.35rem;
  font-size: 1.25rem;
  color: var(--app-message-background-color);
  margin-left: 0.5rem;
}

.p-toast .p-toast-message .p-toast-icon-close {
  color: var(--app-message-background-color);
}

.p-toast
  .p-toast-message
  .p-toast-message-content
  .p-icon:not(.p-toast-icon-close-icon) {
  width: 1.25rem;
}
