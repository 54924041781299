.p-sidebar {
  color: var(--app-titles-color);
}

.p-sidebar-right {
  width: 632px;
  background: var(--app-background-color);
  top: 12px;
  right: 12px;
  border-radius: 8px;
  height: calc(100vh - 24px);

  .p-sidebar-header {
    height: 64px;
    padding: 16px 24px 16px 24px;
    gap: 8px;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid var(--app-p-steps-border-color);
    .p-sidebar-icon {
      color: var(app-paragraph-color);
      top: -7px;
    }
    .p-icon {
      width: 14.03px;
      height: 11.03px;
      color: var(--app-paragraph-color);
    }
  }

  .p-sidebar-content {
    padding: 48px 24px 48px 24px;
    gap: 48px;
  }

  .p-sidebar-footer {
    height: 76px;
    padding: 16px 24px 16px 24px;
    border-top: 1px solid var(--app-p-steps-border-color);
  }
}
