.p-chart.p-element {
  height: calc(100% - 17px);
  width: 100%;
}

p-chart {
  height: calc(100% - 17px);
  width: 100%;
  div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
