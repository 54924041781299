.p-calendar {
  width: 121px;
  display: flex;
  flex-direction: row-reverse;
  height: 36px;
  border-radius: 8px;
  border: 1px solid var(--app-sidebar-right-border-color);
  box-shadow: 0px 1px 1px -0.5px #00000033;
  padding: 0px 10px;
  transition: width 1s ease;

  .p-button {
    color: var(--app-input-focus-icon-and-buttons-color);
    background: var(--app-background-color);
    border: none;
    height: 100%;
    width: 20px;
  }
  .p-button:hover {
    color: var(--app-input-focus-icon-and-buttons-color);
    background: var(--app-background-color);
  }

  .p-inputtext {
    border: none;
    padding: 0px 4px 0px 4px;
    height: 100%;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: var(--app-input-focus-icon-and-buttons-color);
    background: var(--app-background-color);
  }

  .p-inputtext::placeholder {
    color: var(--app-input-focus-icon-and-buttons-color);
    opacity: 1;
  }

  .p-inputtext:enabled:focus {
    box-shadow: none;
    border: none;
  }

  .p-datepicker table td > span.p-highlight {
    color: var(--app-input-focus-icon-and-buttons-color);
    background: var(--app-background-color);
  }

  .p-datepicker {
    left: -285px !important;
    .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
    .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
      color: var(--app-input-focus-icon-and-buttons-color);
    }
    .p-datepicker-header .p-datepicker-next,
    .p-datepicker-header .p-datepicker-prev {
      color: var(--app-input-focus-icon-and-buttons-color);
    }

    .p-yearpicker .p-yearpicker-year.p-highlight,
    .p-monthpicker .p-monthpicker-month.p-highlight {
      color: var(--app-input-focus-icon-and-buttons-color) !important;
      background: var(--app-message-background-color) !important;
    }
  }

  .p-datepicker:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    color: var(--app-input-focus-icon-and-buttons-color) !important;
    background: var(--app-message-background-color) !important;
  }

  .p-datepicker:not(.p-disabled)
    table
    td
    span:not(.p-highlight):not(.p-disabled):hover {
    background: #fffcf6;
    color: var(--app-input-focus-icon-and-buttons-color);
  }

  .p-datepicker table td.p-datepicker-today > span {
    background: var(--app-background-color);
  }

  .p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):focus,
  .p-datepicker:not(.p-disabled)
    .p-monthpicker
    .p-monthpicker-month:not(.p-disabled):focus,
  .p-datepicker:not(.p-disabled)
    table
    td
    span:not(.p-highlight):not(.p-disabled):focus {
    box-shadow: none;
    color: var(--app-input-focus-icon-and-buttons-color) !important;
    background: var(--app-message-background-color) !important;
  }

  .p-datepicker:not(.p-disabled)
    .p-yearpicker
    .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: var(--app-message-background-color) !important;
  }

  .p-datepicker table td > span:focus {
    box-shadow: none;
  }

  .p-datepicker-buttonbar {
    .p-button:first-of-type {
      display: none;
    }

    .p-button {
      width: 100px;
    }
  }
}

.p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.p-calendar-w-btn .p-inputtext {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  box-shadow: none;
  border-color: none;
}

.p-inputwrapper-filled {
  .p-calendar {
    width: 210px !important;
  }

  .p-datepicker {
    left: -200px !important;
  }
}

p-calendar.p-calendar-clearable .p-calendar-w-btn .p-calendar-clear-icon {
  color: var(--app-input-focus-icon-and-buttons-color);
  right: 5px;
  width: 10px;
  height: 10px;
  top: 20px;
}

p-calendar.p-calendar-clearable .p-calendar-w-btn .p-calendar-clear-icon:hover {
  color: var(--app-error-message-color);
}
