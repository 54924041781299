.p-button {
  font-size: 14px;
}

.p-button-label {
  font-weight: 500;
}

.p-selectbutton .p-button {
  color: var(--app-login-selectButton-unfocused-color);
  border: none;
  width: 50%;
}

.p-selectbutton .p-button:focus {
  box-shadow: 0px 1px 1px -0.5px #a0a0a040;
}

.p-selectbutton.p-buttonset.p-component {
  padding: 0.2rem;
}

.p-selectbutton .p-button,
.p-selectbutton.p-buttonset.p-component {
  background-color: var(--app-login-selectButton-focus-background-color);
}

.p-button.p-component.p-highlight {
  background: #ffffff;
  border: none;
  color: var(--app-titles-color);
}

.p-selectbutton.p-buttonset.p-component,
.p-button.p-component.p-highlight {
  border-radius: 6px;
}
